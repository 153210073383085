const Input = (props) => {
  const {
    label,
    labelClass,
    inputClass,
    inputName,
    value,
    onChange,
    placeholder,
    error,
    validator,
    onFocus,
    about,
    disabled,
  } = props;
  return (
    <label className={"Label uppercase bl-dark body-m " + labelClass}>
      {label}
      <input
        disabled={disabled}
        name={inputName}
        type="text"
        className={
          "b-rad-5 bg-grey-2 " + inputClass + (error ? " invalid" : "")
        }
        placeholder={placeholder || "Type..."}
        value={value || ""}
        autoComplete="off"
        onChange={(event) => {
          if ((validator && validator(event.target.value)) || !validator) {
            onChange(event);
          }
        }}
        onFocus={onFocus}
      />
      {about && (!error || error.length === 0) && (
        <span className="text-transform-none grey-1">{about}</span>
      )}
      <span className="text-transform-none invalid">
        {error.length > 0 ? error : ""}
      </span>
    </label>
  );
};

export default Input;
