import "./style.css";
import { useState } from "react";

const altText = {
  1: "man fishing in a small boat",
  2: "woman lying on floor exercising",
  3: "man playing soccer in a park",
  4: "woman playing guitar in a window seat",
  5: "man sitting in front of large speakers",
  6: "woman playing tennis in front of a crowd",
  7: "man riding a scooter",
  8: "woman meditating in front of large lotus flower painting",
  9: "woman teaching a lecture from an iPad, projecting onto a screen",
  10: "woman kneeling, petting a small white dog",
  11: "woman holding up a trophy ",
  12: "woman holding a purse,standing in front of a store window",
  13: "man kneeling on floor, assembling blocks",
  home1: "woman in front of a white board, planning a training session.",
  home2: "woman using tablet, expressing frustration",
  forgot: "man sitting, holding mobile device",
  login: "man sitting at small table, with an open laptop",
  reset: "woman sitting at small table, with an open laptop",
  contact:
    "man standing on a balcony next to a plant, looking at a warning sign.",
  manage_sessions:
    "man relaxing on a chair with feet on table, laptop on lap, and sipping coffee while managing his schedule.",
};

const Illustration = ({ illustration, id }) => {
  // eslint-disable-next-line no-unused-vars
  const [randomImage, setRandomImage] = useState(() => {
    if (illustration)
      return {
        image: `images/Illustrations/${illustration}.svg`,
        altText: altText[illustration],
      };
    else {
      const idx = Math.round(Math.random() * 12);
      return {
        image: `images/Illustrations/${idx + 1}.svg`,
        altText: altText[`${idx + 1}`],
      };
    }
  });
  return (
    <div className="RandomIllustration" id={id}>
      <img src={randomImage.image} alt={randomImage.altText} />
    </div>
  );
};

export default Illustration;
