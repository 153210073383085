import BrandLogo from "@components/BrandLogo";
import { Link, useLocation } from "react-router-dom";
import "./style.css";

// import { useSelector } from "react-redux";
// import { selectCurrentUser } from "../auth/authSlice";

const hideFooter = (currentRoute) => {
  const footerShouldBeHidden = [
    "/profile",
    "/register",
    "/topics",
    "/subscribe",
  ];
  console.log(currentRoute);
  if (footerShouldBeHidden.includes(currentRoute)) {
    return true;
  }
  return false;
};

const Footer = () => {
  // const user = useSelector(selectCurrentUser);
  const location = useLocation();
  if (hideFooter(location.pathname)) {
    return <></>;
  }
  return (
    <footer className="Footer container">
      <div className="flex-row align-center space-between">
        <div className="flex-row align-center">
          <BrandLogo />
        </div>
        <div className="flex-row align-center body-m">
          <div>© 2023 AcesonTap</div>
          <div className="marg-x-12">|</div>
          <div>All rights reserved</div>
          <div className="marg-x-12">|</div>
          <Link to="/privacy-policy" className="green">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
