import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { selectCurrentUser } from "../authSlice";

const statePathMap = {
  INITIAL: { path: "/verify-email", queries: [] },
  EMAIL_VERIFIED: { path: "/topics", queries: [] },
  TOPICS_ADDED: { path: "/subscribe", queries: [] },
};

const buildUrl = ({ path, queries }, next) => {
  let qs = ``;
  if (queries.length > 0) {
    for (let i = 0; i < queries.length; i++) {
      qs = i > 0 ? `${qs}&` : qs;
      qs = `${qs}${queries[i].key}=${queries[i].value}`;
    }
  }
  if (next) {
    qs = qs.length > 0 ? `${qs}&` : qs;
    qs = `${qs}next=${next}`;
  }
  return qs.length > 0 ? `${path}?${qs}` : `${path}`;
};

const RequireAuth = () => {
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);

  const authRedirects = (registrationState) => {
    if (
      statePathMap[registrationState]?.path === location.pathname ||
      location.pathname === "/guide"
    ) {
      return <Outlet />;
    } else if (statePathMap[registrationState]) {
      return (
        <Navigate
          to={buildUrl(statePathMap[registrationState], location.pathname)}
          replace
        />
      );
    } else {
      switch (registrationState) {
        case "ACTIVE":
          if (
            location.pathname === "/register" ||
            location.pathname === "/signin"
          ) {
            return <Navigate to="/" replace />;
          }
          return <Outlet />;
        default:
          return <Navigate to={`/signin?next=${location.pathname}`} replace />;
      }
    }
    // return <Outlet />;
  };

  return authRedirects(currentUser ? currentUser.registrationState : undefined);
};

export default RequireAuth;
