import "./style.css";
import { useState } from "react";
import { mapAbbreviationToCode } from "@utils/countryCode";
import autoFormatter from "./autoFormat";

const PhoneInput = (props) => {
  const {
    label,
    labelClass,
    inputClass,
    inputName,
    value,
    onChange,
    placeholder,
    error,
    validator,
    onFocus,
    about,
    disabled,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [countryCode, setCountryCode] = useState("US");

  return (
    <label className={"Label uppercase bl-dark body-m " + labelClass}>
      {label}
      <div
        className={
          "PhoneInput-container flex-row b-rad-5 " + (error ? " invalid" : "")
        }
      >
        <p className="bg-grey-3">+{mapAbbreviationToCode(countryCode)}</p>
        <input name="countryCode" value={countryCode} hidden />
        <input
          disabled={disabled}
          name={inputName}
          type="text"
          className={"bg-grey-2 flex-1 " + inputClass}
          placeholder={placeholder || "Type..."}
          value={autoFormatter(value.replace(/\D/g, ""), countryCode) || ""}
          autoComplete="off"
          onChange={(event) => {
            if (
              (validator && validator(event.target.value.replace(/\D/g, ""))) ||
              !validator
            ) {
              onChange(event.target.value.replace(/\D/g, ""));
            }
          }}
          onFocus={onFocus}
          aria-label="phone number"
        />
      </div>

      {about && (!error || error?.length === 0) && (
        <span className="text-transform-none grey-1">{about}</span>
      )}
      <span className="text-transform-none invalid">
        {error?.length > 0 ? error : ""}
      </span>
    </label>
  );
};

export default PhoneInput;
