import { Fragment, lazy } from "react";
import { Route } from "react-router-dom";
import { Background1 } from "@components/Background";
import { SignOut, RequireAuth } from "@features/auth";

const AceAcademy = lazy(() => import("@features/aceacademy"));
const Profile = lazy(() => import("@features/profile"));
const SignIn = lazy(() => import("@features/auth"));
const VerifyEmail = lazy(() => import("@features/verifyemail"));
const Subscription = lazy(() => import("@features/subscription"));
const Topics = lazy(() => import("@features/topics"));
const Book = lazy(() => import("@features/book"));

const privateRoutes = (
  <Fragment>
    <Route
      path="/signin"
      element={
        <Background1>
          <SignIn />
        </Background1>
      }
    />

    <Route element={<RequireAuth />}>
      <Route path="/signout" element={<SignOut />} />
      <Route
        path="/guide"
        element={
          <Background1>
            <AceAcademy />
          </Background1>
        }
      />
      <Route
        path="/topics"
        element={
          <Background1>
            <Topics />
          </Background1>
        }
      />
      <Route
        path="/subscribe"
        element={
          <Background1>
            <Subscription />
          </Background1>
        }
      />
      <Route
        path="/verify-email"
        element={
          <Background1>
            <VerifyEmail />
          </Background1>
        }
      />
      <Route path="book">
        <Route path=":username" element={<Book />} />
      </Route>
      <Route
        path="/profile"
        element={
          <Background1>
            <Profile />
          </Background1>
        }
      />
    </Route>
  </Fragment>
);

export default privateRoutes;
