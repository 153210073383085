import { useNavigate } from "react-router-dom";

import "./style.css";

const CrossButton = ({ className }) => {
  const navigate = useNavigate();

  return (
    <button
      className={`CrossButton ${
        className ? className : ""
      } flex-row align-center justify-center fit-content`}
      onClick={() => {
        if (window.history.state && window.history.state.idx > 0) {
          navigate(-1);
        } else {
          navigate("/", { replace: true });
        }
      }}
      aria-label="close"
    >
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.0416 2.0745L10.9254 0.958252L6.49998 5.38367L2.07456 0.958252L0.958313 2.0745L5.38373 6.49992L0.958313 10.9253L2.07456 12.0416L6.49998 7.61617L10.9254 12.0416L12.0416 10.9253L7.61623 6.49992L12.0416 2.0745Z" />
      </svg>
    </button>
  );
};

export default CrossButton;
