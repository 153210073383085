import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../utils/resetState";

const initialState = {
  pronouns: "",
  registrationType: "",
  firstName: false,
  lastName: false,
  userName: false,
  email: false,
  password: false,
  confirmPassword: false,
  phoneNumber: "",
  DOB: false,
  bio: false,
  image: "",
  video: "",
  isError: {
    firstName: false,
    lastName: false,
    userName: false,
    email: false,
    password: false,
    confirmPassword: false,
    phoneNumber: false,
    DOB: false,
    bio: false,
    image: false,
    video: false,
  },
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setPronouns: (state, action) => {
      state.pronouns = action.payload;
    },
    setRegistrationType: (state, action) => {
      state.registrationType = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setConfirmPassword: (state, action) => {
      state.confirmPassword = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setDOB: (state, action) => {
      state.DOB = action.payload;
    },
    setBio: (state, action) => {
      state.bio = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    setVideo: (state, action) => {
      state.video = action.payload;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setPronouns,
  setRegistrationType,
  setFirstName,
  setLastName,
  setUserName,
  setEmail,
  setPassword,
  setConfirmPassword,
  setBio,
  setPhoneNumber,
  setDOB,
  setImage,
  setVideo,
  setIsError,
  reset,
} = registrationSlice.actions;

export const selectPronouns = (state) => state.registration.pronouns;
export const selectRegistrationType = (state) =>
  state.registration.registrationType;
export const selectFirstName = (state) => state.registration.firstName;
export const selectLastName = (state) => state.registration.lastName;
export const selectUserName = (state) => state.registration.userName;
export const selectEmail = (state) => state.registration.email;
export const selectPassword = (state) => state.registration.password;
export const selectConfirmPassword = (state) =>
  state.registration.confirmPassword;
export const selectBio = (state) => state.registration.bio;
export const selectPhoneNumber = (state) => state.registration.phoneNumber;
export const selectDOB = (state) => state.registration.DOB;
export const selectImage = (state) => state.registration.image;
export const selectVideo = (state) => state.registration.video;
export const selectIsError = (state) => state.registration.isError;
export default registrationSlice.reducer;
