const AotLogo = (props) => {
  const { height, width, alt } = props;
  let style;

  if (height) {
    if (width) {
      style = {
        width: width + "px",
        height: height + "px",
      };
    } else {
      style = {
        height: height + "px",
      };
    }
  } else if (width) {
    style = {
      width: width + "px",
    };
  } else {
    style = {
      width: "51px",
    };
  }

  return (
    <img
      className="AotLogo"
      src="/images/Logo.svg"
      style={style}
      alt={alt || "Aces on Tap Logo home"}
    />
  );
};

export default AotLogo;
