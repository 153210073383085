import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from "../features/auth/authSlice";
import registrationReducer from "../features/registration/registrationSlice";
import topicsReducer from "../features/topics/topicsSlice";
import chatReducer from "../features/profile/profileSlice";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const authPersistConfig = { key: "auth", storage, blacklist: ["token"] };

const reducers = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: persistReducer(authPersistConfig, authReducer),
  registration: registrationReducer,
  topics: topicsReducer,
  chats: chatReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [apiSlice.reducerPath, "topics", "registration"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware);
  },
  devTools: true,
});
