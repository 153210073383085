import "./style.css";

const Loader = () => {
  return (
    <div className="Loader">
      <div>
        <div className="Loader-outer"></div>
        <div className="Loader-inner"></div>
        <div className="Loader-inner-inner"></div>
      </div>
    </div>
  );
};

export default Loader;
