import React from "react";
import "./style.css";
import BrandLogo from "@components/BrandLogo";
import Button from "@components/Button";
import { useNavigate } from "react-router-dom";

const notFoundImg = "/images/not_found.svg";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <div className="logo-header">
        <BrandLogo />
      </div>
      <div className="main-content">
        <div>
          <h1 className="headerText">Something went wrong!</h1>
          <div className="smallText">We couldn’t find this page.</div>
          <div className="smallText">
            Our IT Aces have been alerted, and we’ll look into it.
          </div>
          <Button
            className={"marg-t-30 btn-radius"}
            text={"Back to home Page"}
            w={"198px"}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div>
          <img src={notFoundImg} alt="notFoundImg" />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
