import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSignoutMutation } from "../authApiSlice";
import { revertAll } from "@utils/resetState";
import Loader from "@components/Loader";

const SignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [signout] = useSignoutMutation();

  const handleSignout = async () => {
    await signout().unwrap();
    dispatch(revertAll());
    navigate("/", { replace: true });
  };

  useEffect(() => {
    handleSignout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader />
    </>
  );
};

export default SignOut;
