import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials } from "../../features/auth/authSlice";
import { revertAll } from "../../utils/resetState";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}api`,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  console.log("MAKE REQUEST", args?.params?.topic);
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.originalStatus === 401) {
    console.log(`sending refresh token`);
    // send refresh token to get new access token
    const refreshResult = await baseQuery("/refresh", api, extraOptions);
    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setCredentials(await refreshResult.data));
      console.log(`Refresh result data`);
      console.log(refreshResult.data);
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(revertAll());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Topic", "User"],
  endpoints: (builder) => ({}),
});
