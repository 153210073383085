import "./style.css";

const computeClassName = ({
  secondary = false,
  danger = false,
  tertiary = false,
  uppercase = false,
  className = "",
}) => {
  let CN = `${className}`;
  if (secondary) {
    CN = `${CN} secondary`;
  }
  if (danger) {
    CN = `${CN} danger`;
  }
  if (tertiary) {
    CN = `${CN} tertiary`;
  }
  if (uppercase) {
    CN = `${uppercase}`;
  }
  return CN;
};

const ButtonTW = ({
  secondary = false,
  danger = false,
  tertiary = false,
  text,
  type = "submit",
  onClick = () => {},
  textUppercase = true,
  childAfter,
  childBefore,
  className = "",
}) => {
  return (
    <button
      type={type}
      className={`${className} ${computeClassName({
        secondary,
        danger,
        tertiary,
        className: "Button px-6 py-5 ButtonTW",
      })}`}
      onClick={onClick}
    >
      {childBefore}
      <h5 className="medium w-fit">{text}</h5>
      {childAfter}
    </button>
  );
};
const Button = ({
  secondary,
  danger,
  tertiary,
  text,
  w,
  h,
  onClick: clickAction,
  icon,
  iconPng,
  iconPngWidth = "50px",
  iconPngHeight = "50px",
  disabled,
  type,
  className,
  ariaLabel,
  smallText = false,
  tabs = false,
  nextIcon = "/images/navigate_next_inactive",
}) => {
  let width = w ? w : "fit-content";
  let height = h;

  const classes = `Button${secondary ? " secondary" : ""} ${
    danger ? " danger" : ""
  } ${className ? className : ""}`;
  const renderChildrenComponents = () => {
    if (tabs) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{}}>
            <img
              src={iconPng}
              style={{ height: iconPngHeight, width: "fit-content" }}
            />
            <h5 className="medium">{text}</h5>
          </div>
          <img
            src={nextIcon}
            style={{ height: iconPngHeight, width: "fit-content" }}
          />
        </div>
      );
    } else {
      return (
        <>
          {icon && <span className="Button-icon">{icon}</span>}
          <h4 className="medium uppercase">{text}</h4>
        </>
      );
    }
  };
  return (
    <button
      type={type || "button"}
      disabled={disabled}
      className={classes}
      style={{ width, height }}
      onClick={(event) => {
        if (clickAction) clickAction(event);
      }}
      aria-label={ariaLabel}
    >
      {renderChildrenComponents()}
    </button>
  );
};

export default Button;
export { ButtonTW };
