import "./style.css";

const DatePicker = (props) => {
  const {
    label,
    labelClass,
    inputClass,
    inputName,
    value,
    onChange,
    placeholder,
    error,
    onFocus,
    disabled,
  } = props;
  return (
    <label className={"Label uppercase bl-dark body-m " + labelClass}>
      {label}
      <input
        disabled={disabled}
        name={inputName}
        type="date"
        className={"b-rad-5 bg-grey-2 " + inputClass}
        value={value || ""}
        onChange={onChange}
        onFocus={onFocus}
      />
    </label>
  );
};

export default DatePicker;
