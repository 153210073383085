import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../utils/resetState";

const initialState = {
  user: {},
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      console.log("LOG ACTION");
      console.log(action);
      state.user = user;
      state.token = accessToken;
    },
    setUserState: (state, action) => {
      const { registrationState } = action.payload;
      state.user.registrationState = registrationState;
    },
    signOut: (state, action) => {
      state.user = {};
      state.token = null;
    },
  },
});

export const { setCredentials, signOut, setUserState } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
