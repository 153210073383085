import "./style.css";
import AotLogo from "./AotLogo";
import AotText from "./AotText";

import { Link } from "react-router-dom";

const BrandLogo = () => {
  return (
    <Link to="/" className="BrandLogo flex-row">
      <AotLogo />
      <AotText />
    </Link>
  );
};

export { AotLogo, AotText };
export default BrandLogo;
