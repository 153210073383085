import "./style.css";
import { Input, Password } from "@components/Input";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../authSlice";
import { useSigninMutation } from "../authApiSlice";
import Button from "@components/Button";
import CrossButton from "@components/CrossButton";
import Illustration from "@components/Illustration";
import useQuery from "@hooks/useQuery";

const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const [signin, { isLoading }] = useSigninMutation();
  const dispatch = useDispatch();

  const query = useQuery();
  const next = query.get("next");

  useEffect(() => {
    if (errorMessage.length > 0) {
      setErrorMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userData = await signin({ username, password }).unwrap();
      dispatch(setCredentials(userData));
      setUsername("");
      setPassword("");
      if (next !== null) {
        navigate(next);
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      if (!err?.status) {
        setErrorMessage("No Server Response");
      } else if (err.status === 400) {
        setErrorMessage("Missing Username or Password");
      } else if (err.status === 401) {
        setErrorMessage("Unauthorized");
      } else if (err.status === 403) {
        setErrorMessage(err.data.info);
      } else {
        setErrorMessage("Login Failed");
      }
    }
  };

  const handleUserInput = (e) => setUsername(e.target.value);
  const handlePasswordInput = (e) => setPassword(e.target.value);

  return (
    <div
      className="Signin b-rad-5 card flex-row"
      style={{ backgroundImage: "url(images/aot-background.png)" }}
    >
      <div className="flex-col justify-center">
        <Illustration illustration="login" />
      </div>
      <div className="v-sep marg-l-75 marg-r-60 align-self-stretch h-auto"></div>
      <div className="Signin-pane" style={{ backgroundImage: "images/" }}>
        <div className="flex-row space-between">
          <h3 className="medium marg-b-8">Sign in</h3>
          <CrossButton className="Signin-cross-button" />
        </div>
        <h4 className="medium marg-b-40">Let's get you into your account!</h4>
        <form
          onSubmit={handleSubmit}
          encType="application/x-www-form-urlencoded"
        >
          <div className="marg-b-40">
            <Input
              disabled={isLoading}
              label="Username/Email"
              labelClass=""
              inputName="username"
              value={username}
              onChange={handleUserInput}
              placeholder="Username here"
              error=""
              required
            />
            <Password
              disabled={isLoading}
              label="Password"
              labelClass="marg-t-48"
              inputName="password"
              value={password}
              placeholder="Password here"
              error=""
              onChange={handlePasswordInput}
              required
            />

            <div className="flex-row">
              {errorMessage.length > 0 ? (
                <>
                  <object
                    type="image/svg+xml"
                    data="images/error.svg"
                    className="marg-r-8"
                    aria-label="icon"
                  ></object>
                  <p className="body-m">
                    <span className="invalid">{errorMessage}</span>
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>

            <p className="text-right body-m grey-1">
              Forgot Password?{" "}
              <Link
                to="/forgot-password"
                className="text-decoration-none green"
              >
                Reset Password
              </Link>
            </p>
          </div>
          <Button type="submit" text="Sign in" w="90px" ariaLabel="sign in" />
        </form>
        <p className="body-m grey-1 marg-t-12">
          Don’t have an account yet?{" "}
          <Button
            text={"Register Now"}
            onClick={() => {
              navigate("/register");
            }}
            secondary
            w="150px"
            h="30px"
            className="register-button marg-l-4"
          />
        </p>
      </div>
    </div>
  );
};

export default Signin;
