import {
  Background1,
  EmptyBackground,
  Background3,
} from "@components/Background";
import { Route } from "react-router-dom";
import { lazy, Fragment } from "react";

const Home = lazy(() => import("@features/home"));
const Contact = lazy(() => import("@features/contact"));
const FAQ = lazy(() => import("@features/faq"));
const About = lazy(() => import("@features/about"));
const ResetPassword = lazy(() => import("@features/resetpassword"));
const ForgotPassword = lazy(() => import("@features/forgotpassword"));
const SearchPage = lazy(() => import("@features/searchpage"));
const Profile = lazy(() => import("@features/profile"));
const Register = lazy(() => import("@features/registration"));
const TNC = lazy(() => import("@features/tnc"));
const Privacy = lazy(() => import("@features/privacypolicy"));
const EmbedTNC = lazy(() => import("@features/termly/tnc"));
const EmbedPrivacy = lazy(() => import("@features/termly/privacypolicy"));
const CookiePolicy = lazy(() => import("@features/cookiepolicy"));
const Survey = lazy(() => import("@features/survey"));

const publicRoutes = (
  <Fragment>
    <Route path="/survey" element={<Survey />} />

    <Route
      path="/"
      element={
        <EmptyBackground>
          <Home />
        </EmptyBackground>
      }
    />
    <Route
      path="/profile/:username"
      element={
        <Background1>
          <Profile />
        </Background1>
      }
    />
    <Route path="/search" element={<SearchPage />} />
    <Route
      path="/about"
      element={
        <Background3>
          <About />
        </Background3>
      }
    />
    <Route
      path="/contact"
      element={
        <Background1>
          <Contact />
        </Background1>
      }
    />
    <Route
      path="/faq"
      element={
        <Background3>
          <FAQ />
        </Background3>
      }
    />
    <Route
      path="/register"
      element={
        <Background1>
          <Register />
        </Background1>
      }
    />
    <Route
      path="/tnc"
      element={
        <Background1>
          <TNC />
        </Background1>
      }
    />
    <Route
      path="/privacy-policy"
      element={
        <Background1>
          <Privacy />
        </Background1>
      }
    />
    <Route
      path="/cookie-policy"
      element={
        <Background1>
          <CookiePolicy />
        </Background1>
      }
    />
    <Route
      path="/termly-embed-tnc"
      element={
        <Background1>
          <EmbedTNC />
        </Background1>
      }
    />
    <Route
      path="/termly-embed-privacy-policy"
      element={
        <Background1>
          <EmbedPrivacy />
        </Background1>
      }
    />
    <Route
      path="/reset-password"
      element={
        <Background1>
          <ResetPassword />
        </Background1>
      }
    />
    <Route
      path="/forgot-password"
      element={
        <Background1>
          <ForgotPassword />
        </Background1>
      }
    />
  </Fragment>
);

export default publicRoutes;
