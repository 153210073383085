import { Outlet } from "react-router-dom";

import Navbar from "../../features/navbar";
import Footer from "../../features/footer";

const Layout = () => {
  return (
    <>
      <Navbar />

      <main className="App">
        <Outlet />
      </main>

      <Footer />
    </>
  );
};

export default Layout;
