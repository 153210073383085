const EmptyBackground = ({ children }) => {
  return (
    <>
      <div className="Background"></div>
      {children}
    </>
  );
};

export default EmptyBackground;
