const autoFormatter = (phoneNumber, country) => {
  switch (country) {
    case "US":
      return formatUSnumber(phoneNumber);
    case "IN":
      return formatINnumber(phoneNumber);
    default:
      return phoneNumber;
  }
};

const formatUSnumber = (phoneNumber) => {
  if (phoneNumber?.length < 4) {
    return phoneNumber;
  } else if (phoneNumber?.length < 7) {
    return `(${phoneNumber.slice(0, 3)})-${phoneNumber?.slice(3)}`;
  } else {
    return `(${phoneNumber?.slice(0, 3)})-${phoneNumber?.slice(
      3,
      6
    )}-${phoneNumber?.slice(6)}`;
  }
};

const formatINnumber = (phoneNumber) => {
  return phoneNumber;
};

export default autoFormatter;
