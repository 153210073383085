import BrandLogo from "@components/BrandLogo";
import ScreenReaderNavbarDropdown from "./SRNavbarDropdown";
import "./style.css";

import { useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";
import ProfileScreenReaderNavbarDropdown from "./ProfileSRNavbarDropdown";
import { useLocation } from "react-router-dom";

const whiteDropdownButtonText = (currentRoute) => {
  const bg1 = ["/faq", "/about"];
  if (bg1.includes(currentRoute.pathname)) {
    return true;
  }
  return false;
};

const aboutAOTDropdownItems = [
  { link: "/about", text: "About Us", marker: "/images/bullet.svg" },
  {
    link: "/faq",
    text: "Frequently Asked Questions",
    marker: "/images/bullet.svg",
  },
  { link: "/tnc", text: "Terms & Conditions", marker: "/images/bullet.svg" },
  {
    link: "privacy-policy",
    text: "Privacy Policy",
    marker: "/images/bullet.svg",
  },
  { link: "/contact", text: "Contact Us", marker: "/images/bullet.svg" },
];

const aceProfileDropdownItems = [
  { link: "/profile", text: "Profile", marker: "/images/profile.svg" },
  { link: "/guide", text: "Ace Academy", marker: "/images/question.svg" },
  { link: "/signout", text: "Sign Out", marker: "/images/sign-out.svg" },
];

const clientProfileDropdownItems = [
  { link: "/profile", text: "Profile", marker: "/images/profile.svg" },
  { link: "/signout", text: "Sign Out", marker: "/images/sign-out.svg" },
];

const userProfilePicture = (user) => {
  return (
    <img
      src={user.profilePicture}
      className="Navbar-profile-picture mr-3"
      aria-label={`${user.userName} profile`}
      alt={`${user.userName} profile`}
    />
  );
};

const Navbar = () => {
  const user = useSelector(selectCurrentUser);
  const signInRef = useRef();
  const location = useLocation();
  const aceUser = user.userType === "ACE";

  return (
    <nav
      className={`Navbar px-2 sm:px-0 container ${whiteDropdownButtonText(location) ? "" : "green"
        }`}
    >
      <div className="flex-row space-between align-center pad-y-32 w-100">
        <BrandLogo />
        <div className="flex-row align-center">
          <ScreenReaderNavbarDropdown
            items={aboutAOTDropdownItems}
            header={{ text: "About Aces on Tap" }}
          />
          {user && user.firstName ? (
            <ProfileScreenReaderNavbarDropdown
              items={
                aceUser ? aceProfileDropdownItems : clientProfileDropdownItems
              }
              header={{
                text: user?.firstName,
                marker: userProfilePicture(user),
              }}
            />
          ) : (
            <Link
              className="Navbar-signin card b-rad-5 pad-x-24 pad-y-6"
              to="/signin"
              onClick={() => signInRef.current.blur()}
              ref={signInRef}
            >
              <h3 className="medium green">Register/Sign In</h3>
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
