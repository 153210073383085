import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

// import DatePicker from "../components/DatePicker";
import Layout from "@components/Layout";
import Error404 from "@features/404";
import Loading from "@components/Loader";
import privateRoutes from "./private";
import publicRoutes from "./public";

const RoutesComponent = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          {privateRoutes}
          {publicRoutes}
        </Route>

        {/* Sandbox routes */}
        {/* <Route
          path="/dpk"
          element={
            <Background1>
              <DatePickerO />
              <DatePicker />
            </Background1>
          }
        /> */}
        {/* 404 Route */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponent;
