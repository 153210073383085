const mapAbbreviationToCode = (abbreviation) => {
  switch (abbreviation) {
    case "US":
      return "1";
    case "GB":
      return "44";
    case "AU":
      return "61";
    case "FR":
      return "33";
    case "DE":
      return "49";
    case "IN":
      return "91";
    default:
      return "";
  }
};

const mapCodeToAbbreviation = (code) => {
  switch (code) {
    case "1":
      return "US";
    case "44":
      return "GB";
    case "61":
      return "AU";
    case "33":
      return "FR";
    case "49":
      return "DE";
    case "91":
      return "IN";
    default:
      return "";
  }
};

export { mapAbbreviationToCode, mapCodeToAbbreviation };
