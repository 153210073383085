const TextArea = (props) => {
  const {
    label,
    name,
    rows,
    labelClass,
    textareaClass,
    value,
    onChange,
    onFocus,
    placeholder,
    maxLength,
    validator,
    error,
    disabled,
  } = props;

  return (
    <label className={"Label uppercase bl-dark body-m " + labelClass}>
      {label}
      <textarea
        disabled={disabled}
        autoComplete="off"
        name={name}
        rows={rows || 3}
        className={
          "b-rad-5 bg-grey-2 " + textareaClass + (error ? " invalid" : "")
        }
        placeholder={placeholder || "Type..."}
        value={value || ""}
        onChange={(event) => {
          if ((validator && validator(event.target.value)) || !validator) {
            onChange(event);
          }
        }}
        onFocus={onFocus}
      />
      <div className="w-100 flex-row space-between">
        <span className="invalid text-transform-none">{error}</span>
        <div>
          <span className={value?.length > maxLength ? "invalid" : ""}>
            {!value?.length ? 0 : value?.length}
          </span>
          <span>/{maxLength}</span>
        </div>
      </div>
    </label>
  );
};

export default TextArea;
