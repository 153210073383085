import "./App.css";

import { BrowserView, MobileView } from "react-device-detect";
import { ToastProvider } from "@radix-ui/react-toast";
import RoutesComponent from "./routes";
export let socket;

function App() {
  return (
    <>
      <BrowserView>
        <ToastProvider>
          <RoutesComponent />
        </ToastProvider>
      </BrowserView>
      <MobileView>
        <ToastProvider>
          <RoutesComponent />
        </ToastProvider>
      </MobileView>
    </>
  );
}

export default App;
