import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../utils/resetState";

const initialState = {
  chats: [],
  conversation: {},
};

export const profileSlice = createSlice({
  name: "chats",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setConversation: (state, action) => {
      state.conversation = action.payload;
    },
    updateConversation: (state, action) => {
      if (state?.conversation?.messages) {
        const newConver = { ...state.conversation };
        newConver.messages.push(action.payload);
        state.conversation = { ...newConver };
      } else {
        state.conversation = action.payload;
      }
    },
    updateChatMessage: (state, action) => {
      state.chats.forEach((chat) => {
        if (chat.userName === action.payload.sender) {
          chat.content = action.payload.content;
        }
      });
    },
  },
});

export const {
  setChats,
  setConversation,
  updateConversation,
  updateChatMessage,
} = profileSlice.actions;

export const selectChats = (state) => state.chats.chats;
export const selectConversation = (state) => state.chats.conversation;

export default profileSlice.reducer;
