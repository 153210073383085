import ScreenReaderDropdownItem from "../ScreenReaderDropdownItem";

const ProfileScreenReaderNavbarDropdown = ({ header, items }) => {
  const { text, marker } = header;
  return (
    <div className="flex justify-center">
      <div>
        <div className="dropdown relative">
          <button
            className="
            dropdown-toggle
            px-4
            py-1
            green
            leading-tight
            rounded
            focus:outline-none
            flex
            items-center
            whitespace-nowrap
            body-xl medium
            bg-white
            card
          "
            type="button"
            id="dropdownMenuButton2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {marker}
            Hello {text}
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="caret-down"
              className="w-2 ml-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
              ></path>
            </svg>
          </button>
          <ul
            className="
            dropdown-menu
            min-w-max
            max-w-max
            absolute
            hidden
            bg-white
            text-base
            z-50
            float-left
            py-2
            list-none
            text-left
            rounded-lg
            shadow-lg
            mt-1
            m-0
            bg-clip-padding
            border-none
          "
            aria-labelledby="dropdownMenuButton2"
          >
            {items.map((item) => {
              const { link, text, marker } = item;
              return (
                <ScreenReaderDropdownItem
                  link={link}
                  text={text}
                  marker={marker}
                  key={link}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProfileScreenReaderNavbarDropdown;
