import "./style.css";

const Background3 = ({ children }) => {
  return (
    <>
      <div
        className="Background Background3"
        style={{
          backgroundImage:
            "url(images/oval-right.svg), url(images/oval-left.svg)",
        }}
      ></div>
      {children}
    </>
  );
};

export default Background3;
