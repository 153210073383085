const Dropdown = (props) => {
  const {
    label,
    value,
    onChange,
    options,
    selectClass,
    labelClass,
    disabled,
    hidden,
    inputName = "",
    placeholder = "",
  } = props;
  return (
    <label className={"Label uppercase bl-dark body-m " + labelClass}>
      {label}
      <select
        disabled={disabled}
        className={
          (value === "" ? "b-rad-5 bg-grey-2 grey-1" : "b-rad-5 bg-grey-2") +
          ` ${selectClass}`
        }
        value={value}
        onChange={onChange}
        name={inputName}
      >
        <option hidden={hidden} value="">
          {placeholder || "Select"}
        </option>
        {options.map((option) => {
          return (
            <option value={option.value} key={option.value}>
              {option.text}
            </option>
          );
        })}
      </select>
    </label>
  );
};

export default Dropdown;
