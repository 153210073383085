import { useState } from "react";
import "./style.css";

const Password = (props) => {
  const {
    label,
    labelClass,
    inputClass,
    inputName,
    value,
    onChange,
    onFocus,
    placeholder,
    error,
    disabled,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <label className={"Label Password uppercase bl-dark body-m " + labelClass}>
      {label}
      <input
        disabled={disabled}
        autoComplete="off"
        name={inputName}
        type={showPassword ? "text" : "password"}
        className={
          "b-rad-5 bg-grey-2 " + inputClass + (error ? " invalid" : "")
        }
        placeholder={placeholder || "Type..."}
        value={value || ""}
        onChange={onChange}
        onFocus={onFocus}
      />
      {!showPassword && (
        <img
          className="Password-toggle"
          type="image/svg+xml"
          src="images/eye.svg"
          onClick={() => {
            setShowPassword(true);
          }}
          aria-label="show password toggle"
        />
      )}
      {showPassword && (
        <img
          className="Password-toggle"
          type="image/svg+xml"
          src="images/eye-off.svg"
          onClick={() => {
            setShowPassword(false);
          }}
          aria-label="hide password toggle"
        />
      )}
    </label>
  );
};

export default Password;
