import "./style.css";

import SignIn from "./Signin";
import SignOut from "./Signout";
import RequireAuth from "./RequireAuth";

const Authentication = () => {
  return (
    <div className="blur-container">
      <SignIn />
    </div>
  );
};

export default Authentication;
export { SignOut, RequireAuth };
