import { Link } from "react-router-dom";

const ScreenReaderDropdownItem = ({ link, text, marker }) => {
  return (
    <li key={link}>
      <Link
        className="
        dropdown-item
        py-2
        px-4
        block
        w-full
        whitespace-nowrap
        bg-transparent
        text-gray-700
        hover:bg-gray-100
        flex-row
        justify-start
        align-center
        body-l medium
      "
        to={link}
      >
        {marker ? (
          <div className="Navbar-dropdown-menu-marker inline-block mr-2">
            <object
              type="image/svg+xml"
              data={marker}
              className=""
              aria-label="icon"
            ></object>
          </div>
        ) : (
          <></>
        )}
        {text}
      </Link>
    </li>
  );
};

export default ScreenReaderDropdownItem;
