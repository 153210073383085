import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../utils/resetState";

const initialState = {
  topics: [],
  userTopics: [],
};

export const topicsSlice = createSlice({
  name: "topics",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setTopicsList: (state, action) => {
      state.topics = action.payload;
      if (state.userTopics.length > 0) {
        for (let i; i < state.userTopics.length; i++) {
          state.topics = state.topics.filter((item) => {
            return item.topic !== state.userTopics[i];
          });
        }
      }
    },
    addUserTopic: (state, action) => {
      state.userTopics.push(action.payload);
      state.topics = state.topics.filter((item) => {
        return item.topic !== action.payload;
      });
    },
    removeUserTopic: (state, action) => {
      state.topics.push({ topic: action.payload });
      state.userTopics = state.userTopics.filter((item) => {
        return item !== action.payload;
      });
    },
    reset: () => initialState,
  },
});

export const { setTopicsList, addUserTopic, removeUserTopic, reset } =
  topicsSlice.actions;

export const selectTopicsList = (state) => state.topics.topics;
export const selectUserTopicsList = (state) => state.topics.userTopics;

export default topicsSlice.reducer;
