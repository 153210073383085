const AotText = (props) => {
  const { height, width } = props;
  let style;

  if (height) {
    if (width) {
      style = {
        width: width + "px",
        height: height + "px",
      };
    } else {
      style = {
        height: height + "px",
      };
    }
  } else if (width) {
    style = {
      width: width + "px",
    };
  } else {
    style = {
      width: "43px",
    };
  }

  return (
    <img
      className="AotText"
      src="/images/AoT.svg"
      style={style}
      alt="Aces on Tap Logo home"
    />
  );
};

export default AotText;
