import "./style.css";

const Background1 = ({ children }) => {
  return (
    <>
      <div
        className="Background Background1"
        style={{ backgroundImage: "url(images/background.svg)" }}
      ></div>
      {children}
    </>
  );
};

export default Background1;
